<template>
	<div>
		<div class="flex flex-col bg-white border rounded">
			<div class="w-full border-bottom p-[16px] flex flex-col md:flex-row md:items-center gap-2">
				<div class="flex items-center flex-grow gap-2">
					<img src="@/assets/img/icons/search.svg" class="shrink-0" alt="">
					<input placeholder="Search" class="border-none outline-none text-sm flex-grow" />
				</div>
				<button @click="showConfigureChargeModal = true" class="shrink-0 flex items-center gap-[16px] font-medium justify-center py-3 px-4 bg-[#FAFAFA] rounded border text-[#444854]">
					<img src="@/assets/img/icons/plus_black.svg" alt="">
					Configure new charge
				</button>
			</div>
			<b-table show-empty :busy="fetching" responsive striped :items="configuredCharges" :fields="fields" class='cursor-pointer' @row-clicked="viewDetails">
				<template #table-busy>
					<div class="text-center text-secondary my-2">
						<strong>Loading...</strong>
					</div>
				</template>
				<template #empty>
					<h4 class="text-sm text-center py-5">No charge type has been configure, click on “configure new charge” to configure a charge type.</h4>
				</template>
				<template #cell(sn)="data">
					{{ (currentPage - 1) * perPage + data.index + 1 }}
				</template>
				<template #cell(type)="data">
					<p class="text-sm text-[#737876] whitespace-nowrap">
						<template v-if="computedChargeTypes.length">
							<span class="text-[#101211] uppercase">
								{{computedChargeTypes.find(el => el.id === data.item.additional_charge_type_id).short_name}}
							</span>
							<span>
								{{computedChargeTypes.find(el => el.id === data.item.additional_charge_type_id).name}}
							</span>
						</template>
						<span v-else>Loading...</span>
					</p>
				</template>
				<template #cell(fees)="data">
					<p class="text-sm text-[#737876] whitespace-nowrap">
						<template v-if="data.item.charge_type === 'flat'">N{{data.item.charge_value}}</template>
						<template v-else>{{data.item.charge_value}}%</template>
					</p>
				</template>
				<template #cell(countries)="data">
					<div class="flex flex-col gap-1 text-[#101211] font-medium">
						<p class="text-sm">{{data.item.country_currently_active_in.name}}</p>
						<div class="flex flex-wrap gap-2">
							<p class="text-[#737876] text-xs" v-for="n,i in data.item.cities_currently_active_in" :key="i">
								{{ n.city_name }}
							</p>
						</div>
					</div>
				</template>
				<template #cell(desc)="data">
					<p class="text-sm text-[#737876]">{{data.item.description}}</p>
				</template>
				<template #cell(configured_by)="data">
					<p class="text-sm text-[#2C8EED] font-medium">{{ data.item.updated_by_data?.fname || '' }} {{data.item.updated_by_data?.lname || ''}}</p>
				</template>
				<template #cell(time)="data">
					<div class="flex flex-col gap-1 text-xs text-[#313533] font-medium">
						<p>{{ moment(data.item.created_at).format('LT') }}</p>
						<p class="whitespace-nowrap">{{ moment(data.item.created_at).format('LL') }}</p>
					</div>
				</template>
				<template #cell(status)="data">
					<p class="text-xs py-1 px-2 rounded-full font-medium w-fit"
						:class="data.item?.is_active ? 'st_active' : 'st_inactive'"
					>
						{{ data.item.is_active ? 'Active' : 'Inactive' }}
					</p>
				</template>
				<template #cell(action)="data">
					<b-dropdown size="sm" variant="link" right toggle-class="text-decoration-none" :no-caret="true">
						<template #button-content>
							<i class="fe fe-more-horizontal"></i>
						</template>
						<b-dropdown-item class="text-sm font-medium" @click.prevent="openActivateChargeModal(data.item.id)">Activate</b-dropdown-item>
						<b-dropdown-item class="text-sm font-medium text-[#000005]" @click.prevent="initEditConfigure(data.item)">Modify</b-dropdown-item>
						<b-dropdown-item class="text-sm font-medium text-danger" @click.prevent="deleteConfiguredCharge(data.item.id)">Delete</b-dropdown-item>
					</b-dropdown>
				</template>
			</b-table>
			<b-pagination v-if="configuredCharges.length" v-model="currentPage" :total-rows="configuredChargesMetadata.total" :per-page="perPage"></b-pagination>
		</div>
	</div>
</template>

<script setup>
import {ref, computed, watch} from 'vue'
import { axiosInstance as axios } from '@/plugins/axios'
import router from '@/router'
import { useFetchChargeTypes } from '../../composables/charges/chargeTypes/index'
import { useCreateConfigureCharge, useFetchConfiguredCharges, useDeleteConfiguredCharge, useActivateConfiguration } from '../../composables/charges/configure/index'
import moment from 'moment'


const { fetchAllChargeTypesWithoutLimit, allChargeTypes } = useFetchChargeTypes()
const { loading: deleting, deleteConfiguredCharge } = useDeleteConfiguredCharge()
const { showConfigureChargeModal, initEditConfigure } = useCreateConfigureCharge()
const { currentPage, perPage, search, configuredCharges, configuredChargesMetadata, fetchConfiguredCharges, loading: fetching } = useFetchConfiguredCharges()
const { openActivateChargeModal } = useActivateConfiguration()

const fields = [
	{ key: 'sn', label: 'S/N' },
	{ key: 'type', label: 'Charges type' },
	{ key: 'fees', label: 'Fees' },
	{ key: 'countries', label: 'Countries/Cities' },
	{ key: 'configured_by', label: 'Configured by' },
	{ key: 'time', label: 'Time/date' },
	{ key: 'status', label: 'Status' },
	{ key: 'action', label: 'Action' },
]

const viewDetails = (val) => {
	router.push({name: 'ConfiguredChargeDetail', params: {configureId: val.id}})
}

const computedChargeTypes = computed(() => {
	return allChargeTypes.value
})

watch([currentPage, search], () => {
	fetchConfiguredCharges()
})

fetchAllChargeTypesWithoutLimit()
fetchConfiguredCharges()
currentPage.value = 1
</script>

<style scoped>
h1,h2,h3,h4,h5,h6,span, label, p{
	margin: 0;
}

.st_active{
	border: 1px solid #0DAC5C;
	color: #0DAC5C;
	background: #F5FFFA;
}

.st_inactive{
	background-color: #FAFAFA;
	border: 1px solid #6E717C;
	color: #6E717C;
}
</style>